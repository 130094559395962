<script>
import DocumentListComponent from "./components/resources/DocumentListComponent.vue";
import AutoriryComponent from "./components/resources/AutoriryComponent.vue";
import NewsComponent from "./components/resources/NewsComponent.vue";
import ITCGridComponent from "./components/resources/ITCGridComponent.vue";
import BoxIconGridComponent from "./components/resources/BoxIconGridComponent.vue";
import MultiSliderComponent from "./components/resources/MultiSliderComponent.vue";
import FrecuentAccessComponent from "./components/resources/FrecuentAccessComponent.vue";
import GridLinkComponent from "./components/resources/GridLinkComponent.vue";
import CardGridComponent from "./components/resources/CardGridComponent.vue";
import FormComponent from "./components/resources/FormComponent.vue";
import VideoContainerComponent from "./components/resources/VideoContainerComponent.vue"
import InfoCidiComponent from "./components/resources/InfoCidiComponent.vue"
export default {
  name: "HomeView",
  components: {
    /* eslint-disable */
    DocumentListComponent,
    /* eslint-disable */
    AutoriryComponent,
    /* eslint-disable */
    NewsComponent,
    /* eslint-disable */
    ITCGridComponent,
    /* eslint-disable */
    BoxIconGridComponent,
    /* eslint-disable */
    MultiSliderComponent,
    /* eslint-disable */
    FrecuentAccessComponent,
    /* eslint-disable */
    GridLinkComponent,
    /* eslint-disable */
    CardGridComponent,
    FormComponent,
    VideoContainerComponent,
    InfoCidiComponent
  },
  data() {
    return {
      site: 29,
    };
  },
  mounted() {
    document.title = process.env.VUE_APP_TITLE;
  },
  methods: {
    trLCGA() {
      gtag('event', 'banner_moratoria_sj', {
        'event_category': 'Navigation',
        'event_label': 'Home SJ a Rentas'
      });
    }
  }
};
</script>

<template>

  <!-- Moratoria -->

  <MultiSliderComponent titulo="" :elementos="1" :mostrar-titulo="false" :mostrar-bordes="true" :ajustar="true" :data="[
    {
      desc: null,
      imagen: require('@/assets/img/moratoriadgr.png'),
      url: 'https://rentas.dgrsj.gob.ar/',
      target: 'blank_',
    },
    {
      desc: null,
      imagen: require('@/assets/img/banner digital_mas benef_osse-43.jpg'),
      url: 'https://facturaweb.osse.com.ar/',
      target: 'blank_',
    },
  ]" />

  <!-- <section class="bg-white py-0 mt-4">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mx-auto text-center d-none d-md-block">
          <a class="linkDGR" href="https://rentas.dgrsj.gob.ar/" rel="nofollow" target="_blank" @click="trLCGA">
            <img class="img-responsive img-fluid" src="img/moratoriadgr.png"
              title="Moratoria 2024 - Dirección General de Rentas" alt="Moratoria 2024 - Dirección General de Rentas" />
          </a>
        </div>
        <div class="col-lg-12 d-md-none mx-auto text-center">
          <a class="linkDGR" href="https://rentas.dgrsj.gob.ar/" rel="nofollow" target="_blank" @click="trLCGA">
            <img class="img-responsive img-fluid" src="img/moratoriadgrmov.png"
              title="Moratoria 2024 - Dirección General de Rentas" alt="Moratoria 2024 - Dirección General de Rentas" />
          </a>
        </div>
      </div>
    </div>
  </section> -->

  <!-- Moratoria -->

  <InfoCidiComponent />
  <VideoContainerComponent />

  <CardGridComponent :data="[
    {
      target: '_self',
      title: 'Transparencia',
      url: 'https://web.sanjuan.gob.ar/hacienda/',
    },
    {
      target: '_self',
      title: 'Estado de deuda DGR',
      url: 'https://rentas.dgrsj.gob.ar/Deudas',
    },
    {
      target: '_self',
      title: 'Consulta de vencimientos DGR',
      url: 'https://rentas.dgrsj.gob.ar/CalendarioFiscal',
    },
    {
      target: '_self',
      title: 'Turnos certificados de antecedentes',
      url: 'https://tramite.sanjuan.gov.ar/index.php/tramite/d/54/turnos-en-linea-para-certificados-de-antecedentes',
    },
    {
      target: '_self',
      title: 'Turnos registro civil',
      url: 'https://tramite.sanjuan.gov.ar/index.php/tramite/d/56/turnos-del-registro-civil',
    },
    {
      target: '_self',
      title: 'Requisitos inscripción IPV',
      url: 'https://tramite.sanjuan.gov.ar/index.php/tramite/d/186/requisitos-para-la-inscripcion-en-el-ipv',
    },
    {
      target: '_blank',
      title: 'Asesoría Jurídica y Control de Legalidad de Gobierno',
      url: 'https://tramite.sanjuan.gov.ar/index.php/tramite/d/186/requisitos-para-la-inscripcion-en-el-ipv',
    },
  ]" />

  <ITCGridComponent :columnas="4" titulo="Consultas y Trámites" :data="[
    {
      desc: null,
      icon: 'ptn-i-documento',
      target: '_self',
      title: 'Consulta de expedientes',
      url: '?uid=CUMV-VIEW-ExpedientesComponent',
    },
    {
      desc: null,
      icon: 'forum',
      target: '_self',
      title: 'Consultas y solicitudes',
      url: '?uid=CUMV-VIEW-FormComponent',
    },
    {
      desc: null,
      icon: 'ptn-i-acuerdo',
      target: '_self',
      title: 'Contrataciones',
      url: 'https://compraspublicas.sanjuan.gob.ar/',
    },
    {
      desc: null,
      icon: 'ptn-i-documento',
      target: '_self',
      title: 'Trámites',
      url: 'https://tramite.sanjuan.gob.ar/principal/listar',
    },
  ]" />

  <BoxIconGridComponent titulo="Accesos Rápidos" :data="[
    {
      desc: null,
      icon: '@/assets/iconos/ipv',
      target: '_self',
      title: 'Instituto Provincial de la Vivienda',
      url: 'https://ipv.sanjuan.gob.ar/',
    },
    {
      desc: null,
      icon: '@/assets/iconos/favicon',
      target: '_self',
      title: 'Billetera San Juan',
      url: 'https://billetera.sanjuan.gob.ar/',
    },
    {
      desc: null,
      icon: 'ptn-i-ciudadano-digital',
      target: '_self',
      title: 'Ciudadano Digital',
      url: 'https://cidi.sanjuan.gob.ar/',
    },
    {
      desc: null,
      icon: 'mail',
      target: '_self',
      title: 'Correo Web',
      url: 'https://correo.sanjuan.gob.ar/',
    },
    {
      desc: null,
      icon: 'balance',
      target: '_blank',
      title: 'Asesoría Jurídica y Control de Legalidad de Gobierno',
      url: 'https://asesorialetrada.sanjuan.gob.ar/',
    },
  ]" />

  <NewsComponent titulo="Noticias" />

  <AutoriryComponent :showProtocolo="true" :showLinks="true" :data="[
    {
      title: 'Dr. Marcelo Orrego',
      subtitle: 'Gobernador de la Provincia de San Juan',
      desc: '<div><b>Marcelo Humberto Orrego<\/b> (Santa Lucía, 29 de enero de 1975) abogado y político sanjuanino. Desde diciembre del 2023 es el gobernador de la provincia de San Juan (período 2023-2027). Se desempeñó como intendente del municipio de Santa Lucía durante dos períodos consecutivos, desde el 10 de diciembre de 2011 hasta el mismo día de 2019. Posteriormente ocupó el cargo de Diputado Nacional en representación de la provincia, hasta diciembre del 2023.',
      imagen: require('@/assets/img/MARCELOORREGO-BRILLANTE.png'),
      link: [
        {
          target: '_blank',
          title: 'Ministerio de Economía, Finanzas y Hacienda',
          url: 'https://hacienda.sanjuan.gob.ar/',
          archivo: 'roberto_gutierrez_hacienda.jpg',
          nombre: 'Roberto Gutiérrez',
        },
        {
          target: '_blank',
          title: 'Ministerio de Gobierno',
          url: 'https://gobierno.sanjuan.gob.ar/',
          archivo: 'laura_palma_gobierno.jpg',
          nombre: 'Laura Palma',
        },
        {
          target: '_blank',
          title: 'Ministerio de Salud',
          url: 'https://salud.sanjuan.gob.ar/',
          archivo: 'amilcar_dobladez_salud.jpg',
          nombre: 'Amilcar Dobladez',
        },
        {
          target: '_blank',
          title: 'Ministerio de Familia y Desarrollo Humano',
          url: 'https://desarrollo.sanjuan.gob.ar/',
          archivo: 'carlos_platero_desarrollohumano.jpg',
          nombre: 'Carlos Platero',
        },
        {
          target: '_blank',
          title: 'Ministerio de Educaci\u00f3n',
          url: 'https://educacion.sanjuan.gob.ar/',
          archivo: 'silvia_fuentes_educacion.jpg',
          nombre: 'Silvia Fuentes',
        },
        {
          target: '_blank',
          title: 'Ministerio de Turismo, Cultura y Deportes',
          url: 'https://turismo.sanjuan.gob.ar/',
          archivo: 'guido_romero_turismoculturadeportes.jpg',
          nombre: 'Guido Romero',
        },
        {
          target: '_blank',
          title: 'Ministerio de Producción, Trabajo e Innovación',
          url: 'https://produccion.sanjuan.gob.ar/',
          archivo: 'gustavo_fernandez_produccion.jpg',
          nombre: 'Gustavo Fernández',
        },
        {
          target: '_blank',
          title: 'Ministerio de Infraestructura, Agua y Energía',
          url: 'https://obras.sanjuan.gob.ar/',
          archivo: 'fernando_perea_infraestructura.jpg',
          nombre: 'Fernando Perea',
        },
        {
          target: '_blank',
          title: ' Secretaría de Estado de Seguridad y Orden Público',
          url: 'https://seguridad.sanjuan.gob.ar/',
          archivo: 'gustavo_sanchez_seguridad.jpg',
          nombre: 'Gustavo Federico Sánchez',
        },
        {
          target: '_blank',
          title: 'Secretaría General de la Gobernación',
          url: 'https://secretaria.sanjuan.gob.ar/',
          archivo: 'emilio_achem_secgob.jpg',
          nombre: 'Emilio Achem',
        },
        {
          target: '_blank',
          title: 'Secretaría de Estado de Ambiente y Desarrollo Sustentable',
          url: 'https://ambiente.sanjuan.gob.ar/',
          archivo: 'federico_rios_ambiente.jpg',
          nombre: 'Federico Ríos Yáñez',
        },
        {
          target: '_blank',
          title: 'Ministerio de Minería',
          url: 'https://mineria.sanjuan.gob.ar/',
          archivo: 'juanpablo_perea_mineria.jpg',
          nombre: 'Juan Pablo Perea Fontivero',
        },
        {
          target: '_blank',
          title: ' Asesoría Jurídica y Control de Legalidad de Gobierno',
          url: 'https://asesorialetrada.sanjuan.gob.ar/',
          archivo: 'juanpablo_perea_mineria.jpg',
          nombre: 'Ricardo Alejandro Ferrari',
          nombre2: 'Facundo Rojas Clariá'
        }
      ],
    },
  ]" />
</template>
